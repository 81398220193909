.App {
  text-align: center;
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: #505050;
  align-items: center;
  justify-content: center;
}

img {
  /* object-fit: contain; */
}

.app {}